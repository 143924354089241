export default {
    data() {
        return {
            searchItems: [
                {
                    label: "订单类型",
                    key: "orderType",
                    type: "select",
                    options: [
                        { label: "普通订单", value: 1 },
                        { label: "秒杀订单", value: 3 },
                        { label: "拼团订单", value: 7 },
                        { label: "砍价订单", value: 8 },
                        // { label: "积分兑换订单", value: 4 }, 
                        // { label: "邀请有礼赠送商品订单", value: 5 }, 
                        // { label: "抽奖订单", value: 6 }
                    ]
                },
                { label: "订单编号", key: "tranId" },
                { label: "下单时间", key: "fsDate", type: "date", dateType: "datetimerange" },
                { label: "会员姓名", key: "memName" },
                { label: "会员手机", key: "memPhone" }
            ],
            tableTabs: [
                { label: "全部", value: 'all' },
                { label: "待付款", value: 1 },
                { label: "待发货", value: 2 },
                { label: "已发货", value: 3 },
                { label: "已完成", value: 4 },
                { label: "已取消", value: 5 },
                { label: "已退款", value: 6 },
                { label: "退款中", value: 7 }
            ]
        }
    }
}