<template>
    <div class="page product-manage-page">
        <Search
            :items="searchItems"
            :data.sync="searchData"
            @search="search"
            @reset="reset"
        />
        <!-- <el-radio-group
            v-model="searchData.sta"
            @change="search"
            class="table-tab-container"
        >
            <el-radio-button
                v-for="tab in tableTabs"
                :key="tab.value"
                :label="tab.value"
                >{{ tab.label }}</el-radio-button
            >
        </el-radio-group> -->
        <div class="table-tab-panel">
            <el-radio-group v-model="searchData.sta" size="small" @change="search">
                <el-radio-button
                    v-for="tab in tableTabs"
                    :key="tab.value"
                    :label="tab.value"
                >{{ tab.label }}</el-radio-button>
            </el-radio-group>
        </div>
        <bill-table
            ref="bill_table"
            :bill="tableData"
            v-loading="loading"
            :loading="loading"
            @getTableData="getTableData"
        />
        <div class="pagination">
            <el-pagination
                @size-change="handleSizeChange"
                @current-change="handleCurrentChange"
                :current-page="pageIndex"
                :page-sizes="[10, 20, 50]"
                :page-size.sync="pageSize"
                layout="sizes, prev, pager, next, jumper"
                :total="total"
                :hide-on-single-page="true"
            ></el-pagination>
        </div>
    </div>
</template>

<script>
import data from "./data";
import BillTable from "./billTable";
import { RESET_SEARCH } from "../../events/eventBus";
export default {
    mixins: [data],
    data() {
        return {
            loading: false,
            searchData: {
                sta: "all"
            },
            scb: null,
            pageIndex: 1,
            pageSize: 10,
            total: 0,
            tableData: [],
            visible: false,
            pageData: {},
            dialogVisible: false,
            options: [
                { label: "圆通速递", value: "yuantong" },
                { label: "中通速递", value: "zhongtong" },
                { label: "申通快递", value: "shentong" },
                { label: "韵达", value: "yunda" },
                { label: "顺丰速运", value: "shunfeng" },
                { label: "京东快递", value: "jingdong" },
                { label: "如风达快递", value: "rufengda" },
                { label: "国通快递", value: "guotong" },
                { label: "宅急送", value: "zjs" },
                { label: "EMS", value: "ems" },
                { label: "百世汇通", value: "huitong" },
                { label: "中国邮政小包", value: "chinapost" },
                { label: "德邦", value: "debang" },
                { label: "天天快递", value: "tiantian" },
                { label: "其他", value: "auto" }
            ],
            postType: "",
            postCode: ""
        };
    },
    mounted() {
        this.getTableData();
        this.$EventBus.$on(RESET_SEARCH, () => {
            this.searchData = { sta: "all" };
            this.search();
        });
    },
    methods: {
        getTableData() {
            let dateObj = {};
            if (this.searchData.fsDate) {
                dateObj.fsDateFrom = this.searchData.fsDate[0];
                dateObj.fsDateTo = this.searchData.fsDate[1];
            }
            this.loading = true;
            this.$post("/shop/api/order/list", {
                pageSize: this.pageSize,
                pageIndex: this.pageIndex,
                tranType: "1",
                ...dateObj,
                ...this.searchData
            }).then(data => {
                this.tableData = data.datas;
                this.total = data.recordCount;
                this.loading = false;
                // loading.close();
            });
        },
        search() {
            this.pageIndex = 1;
            this.getTableData();
        },
        billDetail(row) {
            this.pageData = JSON.parse(JSON.stringify(row));
            this.visible = true;
        },
        billRemark() {},
        post(row) {
            this.pageData = JSON.parse(JSON.stringify(row));
            this.dialogVisible = true;
        },
        close() {
            this.dialogVisible = false;
        },
        startPost() {},
        handleSizeChange(size) {
            this.pageSize = size;
            this.getTableData();
        },
        handleCurrentChange(curr) {
            this.pageIndex = curr;
            this.getTableData();
        },
        reset() {}
    },
    components: { BillTable }
};
</script>

<style lang="scss">
.page.product-manage-page {
    position: relative;
    display: flex;
    flex-direction: column;
    .el-table__expand-column {
        visibility: hidden;
    }
    .el-table__expanded-cell {
        padding: 0;
    }
    .el-table__row.expanded {
        margin-top: 30px;
    }
    .table-expand-panel {
        display: flex;
        padding: 5px 10px;
        margin-bottom: 40px;
        background: #eee;
        justify-content: space-between;
        .container {
            display: flex;
            &.btn {
                color: #2d8cf0;
                cursor: pointer;
            }
        }
        .item {
            margin-right: 20px;
        }
    }
    .table-tab-container {
        background-color: #fff;
        padding-left: 20px;
        .el-radio-button__orig-radio:checked + .el-radio-button__inner {
            box-shadow: none;
        }
        .el-radio-button {
            .el-radio-button__inner {
                background: #eee;
                border-bottom: none;
            }

            &.is-active {
                .el-radio-button__inner {
                    background: #fff;
                    border: 1px solid #ccc;
                    border-bottom: none;
                    color: #000;
                }
            }
        }
    }
    .pagination {
        background: #fff;
        .el-pagination {
            padding: 10px 20px;
        }
    }
}
.el-loading-mask {
    .el-loading-spinner i {
        font-size: 28px;
        color: #fff;
    }
    .el-loading-text {
        color: #fff;
    }
}
</style>
