<template>
    <div :class="['bill-table-container', { disabled: loading }]">
        <template v-if="!visible">
            <div class="bill-table-head">
                <div style="width: 30%">商品信息</div>
                <div style="width: 10%">价格（元）及数量</div>
                <div style="width: 10%">收货人信息</div>
                <div style="width: 10%">配送方式</div>
                <div style="width: 10%">订单金额（元）</div>
                <div style="width: 10%">实付金额（元）</div>
                <div style="width: 10%">订单状态</div>
                <div style="width: 10%">操作</div>
            </div>
            <div class="bill-table-body">
                <div
                    v-if="bill.length == 0"
                    style="padding: 20px; text-align: center"
                    class="empty"
                >
                    暂无数据
                </div>
                <el-scrollbar style="height: 100%">
                    <div
                        class="bill-list-item"
                        v-for="it in bill"
                        :key="it.tranId"
                    >
                        <div class="bill-item-head">
                            <div class="left-head">
                                <p>订单编号: {{ it.tranId }}</p>
                                <!-- <p>下单时间: {{ it.fsDate }}</p> -->
                            </div>
                            <div class="right-head">
                                <p class="blue-link" @click="orderDetail(it)">
                                    订单详情
                                </p>
                                <!-- <p class="blue-link" @click="orderRemark(it)">
                                    订单备注
                                </p> -->
                            </div>
                        </div>
                        <div class="bill-item-detail">
                            <div class="picture" style="width: 40%">
                                <div
                                    class="order-item-info"
                                    v-for="item in it.orderShopDtoList"
                                    :key="item.tranPluId"
                                >
                                    <div class="order-item-name-spec">
                                        <img :src="item.iconImg" />
                                        <div>
                                            <p class="order-item-name">
                                                {{ item.pluName }}
                                            </p>
                                            <p
                                                class="order-item-spec"
                                                v-html="
                                                    formatSpec(item.skuDetail)
                                                "
                                            ></p>
                                        </div>
                                    </div>
                                    <div class="order-item-num-price">
                                        <p class="order-item-price">
                                            ￥{{ item.slPrc.toFixed(2) }}
                                        </p>
                                        <p class="order-item-price">
                                            {{ item.qty }}件
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div
                                class="buyer-info table-item"
                                style="width: 10%"
                            >
                                <template v-if="it.address">
                                    <p class="buyer-name">
                                        {{ it.address.linkName }}
                                    </p>
                                    <p class="buyer-phone">
                                        {{ it.address.linkPhone }}
                                    </p>
                                </template>
                            </div>
                            <div
                                class="post-type table-item"
                                style="width: 10%"
                            >
                                {{ deliveryType[it.deliveryType] }}
                            </div>
                            <div
                                class="order-money table-item"
                                style="width: 10%"
                            >
                                ￥{{ it.amt.toFixed(2) }}
                            </div>
                            <div
                                class="truly-money table-item"
                                style="width: 10%"
                            >
                                ￥{{ it.payMoney.toFixed(2) }}
                            </div>
                            <div
                                class="order-status table-item"
                                style="width: 10%"
                            >
                                {{ status[it.sta] }}
                            </div>
                            <div
                                class="order-opt table-item"
                                style="width: 10%"
                            >
                                <template v-if="it.sta == '2'">
                                    <span
                                        v-if="it.deliveryType == '1'"
                                        class="cell-btn-con"
                                        @click="post(it, true)"
                                    >
                                        <!-- <i
                                            class="iconfont icon-wuliu"
                                            title="自提核销"
                                        /> -->
                                        自提核销
                                    </span>

                                    <span
                                        v-else
                                        class="cell-btn-con"
                                        @click="post(it, false)"
                                    >
                                        <!-- <i
                                            class="iconfont icon-wuliu"
                                            title="发货"
                                        /> -->
                                        发货
                                    </span>
                                </template>
                                <div
                                    v-if="it.sta == '3' || it.sta == '4'"
                                    @mouseleave="leaveBtnCon"
                                    class="cell-btn-con"
                                >
                                    <div
                                        class="post-info-panel"
                                        v-if="
                                            it.tranlogisticShopDtos &&
                                            it.tranlogisticShopDtos.length > 0
                                        "
                                    >
                                        <div
                                            class="tab-card-panel"
                                            v-if="it.deliveryType == 2"
                                        >
                                            <div
                                                class="tab-card"
                                                v-for="(
                                                    lst, lsIdx
                                                ) in it.tranlogisticShopDtos"
                                                :key="lst.logisticNo"
                                                :class="{
                                                    active: lsIdx == spLsIdx
                                                }"
                                                @click="
                                                    tabShopDtoList(
                                                        lsIdx,
                                                        it.tranlogisticShopDtos
                                                    )
                                                "
                                            >
                                                包裹 {{ lsIdx + 1 }}
                                            </div>
                                        </div>
                                        <div class="tab-content">
                                            <template
                                                v-if="
                                                    it.deliveryType == 2 &&
                                                    it.tranlogisticShopDtos[
                                                        spLsIdx
                                                    ]
                                                "
                                            >
                                                <p>
                                                    物流公司:
                                                    {{
                                                        it.tranlogisticShopDtos[
                                                            spLsIdx
                                                        ].expComName
                                                    }}
                                                </p>
                                                <p>
                                                    快递单号:
                                                    {{
                                                        it.tranlogisticShopDtos[
                                                            spLsIdx
                                                        ].logisticNo
                                                    }}
                                                </p>
                                            </template>
                                            <p v-else>
                                                自提码:
                                                {{
                                                    it.tranlogisticShopDtos[0]
                                                        .pickCode
                                                }}
                                            </p>
                                        </div>
                                    </div>
                                    <!-- <i
                                        class="iconfont icon-wuliu"
                                        title="发货"
                                    /> -->
                                    物流
                                </div>
                            </div>
                        </div>
                    </div>
                </el-scrollbar>
            </div>
        </template>
        <Detail :visible.sync="visible" :page="pageData" />
        <el-dialog
            :visible.sync="remarkVisible"
            title="订单备注"
            @close="closeRemark"
            width="400px"
        >
            <el-input type="textarea" v-model="remark" :rows="9"></el-input>
            <div class="btn-contaienr mini">
                <p class="search-btn send" @click="closeRemark">取消</p>
                <p class="search-btn send" @click="editRemark">确定</p>
            </div>
        </el-dialog>
        <el-dialog
            :visible="dialogVisible"
            title="订单发货"
            @close="close"
            width="880px"
        >
            <div class="body-panel">
                <el-table
                    ref="pruTable"
                    :data="ddata"
                    max-height="200px"
                    @selection-change="selectPru"
                    v-if="!postTypeFlag"
                >
                    <el-table-column
                        type="selection"
                        width="50"
                    ></el-table-column>
                    <el-table-column
                        prop="pluName"
                        label="商品"
                    ></el-table-column>
                    <el-table-column prop="skuDetail" label="规格">
                        <template slot-scope="scope">
                            <span v-html="scope.row.skuDetail"></span>
                        </template>
                    </el-table-column>
                    <el-table-column
                        prop="slPrc"
                        label="单价"
                    ></el-table-column>
                    <el-table-column prop="qty" label="数量"></el-table-column>
                    <!-- <el-table-column
                        prop="deliveryFlag"
                        label="寄件状态"
                    ></el-table-column> -->
                </el-table>
                <!--<div class="post-type-container">
                    <div
                        :class="['post-type-item', { active: active == 2 }]"
                        @click="tabType(2)"
                    >
                        快递发货
                    </div>
                    <div
                        :class="['post-type-item', { active: active == 1 }]"
                        @click="tabType(1)"
                    >
                        订单自提
                    </div>
                </div>-->
                <div class="post-container">
                    <div
                        class="kuaidi"
                        style="padding: 10px 0"
                        v-if="!postTypeFlag"
                    >
                        <div class="addr-info post-item">
                            <i class="iconfont icon-address"></i>
                            <div>
                                <template v-if="pageData.address">
                                    <p>
                                        {{ pageData.address.linkName || "" }}
                                        {{ pageData.address.linkPhone || "" }}
                                    </p>
                                    <p>
                                        {{
                                            pageData.address.province +
                                            pageData.address.city +
                                            pageData.address.county +
                                            pageData.address.addDetail
                                        }}
                                    </p>
                                </template>
                            </div>
                        </div>
                        <div class="post-info post-item">
                            <i class="iconfont icon-wuliu"></i>
                            <div>
                                <div class="p-i">
                                    <p>快递公司：</p>
                                    <div class="ele-contaienr">
                                        <el-select
                                            v-model="postType"
                                            placeholder="请选择"
                                        >
                                            <el-option
                                                v-for="item in options"
                                                :key="item.value"
                                                :label="item.label"
                                                :value="
                                                    item.value +
                                                    '|' +
                                                    item.label
                                                "
                                            ></el-option>
                                        </el-select>
                                    </div>
                                </div>
                                <div class="p-i">
                                    <p>快递单号：</p>
                                    <div class="ele-container">
                                        <el-input
                                            v-model="postCode"
                                            maxlength="16"
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="ziti" v-else>
                        <el-input
                            v-model="zitiCode"
                            placeholder="请填写自提码"
                        />
                    </div>
                </div>
            </div>
            <div class="btn-contaienr">
                <p class="search-btn send" @click="startPost">{{ btnText }}</p>
            </div>
        </el-dialog>
    </div>
</template>

<script>
import Detail from "./detail";
export default {
    data() {
        return {
            spLsIdx: 0,
            selection: [],
            zitiCode: "",
            active: 2,
            status: {
                ["1"]: "待付款",
                ["2"]: "待发货",
                ["3"]: "已发货",
                ["4"]: "已完成",
                ["5"]: "已取消",
                ["6"]: "已退款",
                ["7"]: "退款中"
            },
            visible: false,
            dialogVisible: false,
            remarkVisible: false,
            pageData: {},
            ddata: [],
            postType: "",
            postCode: "",
            options: [
                { label: "圆通速递", value: "yuantong" },
                { label: "中通速递", value: "zhongtong" },
                { label: "申通快递", value: "shentong" },
                { label: "韵达", value: "yunda" },
                { label: "顺丰速运", value: "shunfeng" },
                { label: "京东快递", value: "jingdong" },
                { label: "如风达快递", value: "rufengda" },
                { label: "国通快递", value: "guotong" },
                { label: "宅急送", value: "zjs" },
                { label: "EMS", value: "ems" },
                { label: "百世汇通", value: "huitong" },
                { label: "中国邮政小包", value: "chinapost" },
                { label: "德邦", value: "debang" },
                { label: "天天快递", value: "tiantian" },
                { label: "其他", value: "auto" }
            ],
            remark: "",
            deliveryType: {
                1: "店铺自提",
                2: "快递配送"
            },
            postTypeFlag: false
        };
    },
    props: {
        bill: {
            type: Array,
            default: () => []
        },
        loading: {
            type: Boolean,
            default: () => false
        }
    },
    computed: {
        btnText() {
            return this.postTypeFlag ? "自提核销" : "确认发货";
        }
    },
    methods: {
        formatSpec(spec) {
            return spec.replace(/["{}]/g, "").split(",").join(" <br> ");
        },
        tabShopDtoList(idx, ls) {
            console.log(ls[idx].expComName, ls[idx].expComCode);
            this.spLsIdx = idx;
        },
        leaveBtnCon() {
            this.spLsIdx = 0;
        },
        selectPru(selection) {
            this.selection = JSON.parse(JSON.stringify(selection)).map(
                item => item.tranPluId
            );
        },
        orderDetail(item) {
            this.$post("/shop/api/order/detail", { tranId: item.tranId }).then(
                data => {
                    this.pageData = data;
                    this.visible = true;
                }
            );
            // this.pageData = JSON.parse(JSON.stringify(item));
            // this.visible = true;
        },
        orderRemark(item) {
            this.remark = item.remark || "";
            this.remarkVisible = true;
        },
        closeRemark() {
            this.remarkVisible = false;
        },
        editRemark() {
            /**
             * 编辑备注
             */
        },
        close() {
            this.active = 2;
            this.ddata = [];
            this.postType = "";
            this.postCode = "";
            this.dialogVisible = false;
        },
        startPost() {
            if (!this.postTypeFlag) {
                if (this.selection.length < 1) {
                    this.$alert("请选择商品", { confirmButton: "确定" });
                    return;
                }
                if (!this.postType) {
                    this.$alert("请选择快递公司", { confirmButton: "确定" });
                    return;
                }
                if (!this.postCode) {
                    this.$alert("请填写快递单号", { confirmButton: "确定" });
                    return;
                }
                this.$post("/shop/api/order/package/deliver", {
                    tranPluIds: this.selection,
                    deliveryType: this.active,
                    expComCode: this.postType.split("|")[0],
                    expComName: this.postType.split("|")[1],
                    logisticNo: this.postCode
                })
                    .then(() => {
                        this.selection = [];
                        this.$emit("getTableData");
                        console.log("成功");
                    })
                    .catch(() => {});
            } else {
                this.$post("/shop/api/order/package/pickup", {
                    pickCode: this.zitiCode
                })
                    .then(() => {
                        this.selection = [];
                        this.zitiCode = "";
                        this.$emit("getTableData");
                        console.log("成功");
                    })
                    .catch(() => {});
            }
            this.close();
        },
        post(item, flag) {
            console.log(item);
            this.postTypeFlag = flag;
            this.ddata = JSON.parse(
                JSON.stringify(item.orderShopDtoList)
            ).filter(item => !item.deliveryFlag && item.deliveryType == 2);
            this.pageData = JSON.parse(JSON.stringify(item));
            this.dialogVisible = true;
        },
        tabType(idx) {
            if (this.active == idx) return;
            this.active = idx;
        }
    },
    components: {
        Detail
    }
};
</script>

<style lang="scss">
.bill-table-container {
    background: #fff;
    padding: 0 20px 20px;
    max-height: 600px;
    overflow: hidden;
    &.disabled {
        overflow: hidden;
    }
    .bill-table-head {
        display: flex;
        background: #f8f8f8;
        font-size: 12px;
        height: 50px;
        line-height: 50px;
        div {
            text-align: center;
        }
    }
    .bill-table-body {
        height: calc(100% - 50px);
        overflow: auto;
        .el-scrollbar__wrap {
            overflow-x: hidden;
        }
        .bill-list-item {
            margin-top: 20px;
            &:hover {
                background: #f5f7fa;
            }
            .bill-item-head {
                display: flex;
                justify-content: space-between;
                background: #eee;
                padding: 10px;
                font-size: 12px;
                .left-head {
                    display: flex;
                    p {
                        margin-right: 20px;
                    }
                }
                .right-head {
                    display: flex;
                    color: #2d8cf0;
                    cursor: pointer;
                    .blue-link {
                        margin: 0 20px;
                        &:hover {
                            color: #777;
                            text-decoration: underline;
                        }
                    }
                }
            }
            .bill-item-detail {
                display: flex;
                font-size: 12px;
                .picture {
                    border-left: 1px solid #eee;
                    .order-item-info {
                        display: flex;
                        border-bottom: 1px solid #eee;
                        .order-item-name-spec {
                            padding: 10px;
                            display: flex;
                            flex: 1;
                            border-right: 1px solid #eee;
                            img {
                                width: 80px;
                                height: 80px;
                                margin-right: 10px;
                            }
                            p {
                                margin-bottom: 14px;
                                &.order-item-spec {
                                    color: #aaa;
                                }
                            }
                        }
                        .order-item-num-price {
                            width: 25%;
                            padding: 10px;
                            border-right: 1px solid #eee;
                            display: flex;
                            flex-direction: column;
                            align-items: center;
                            justify-content: center;
                            p {
                                margin: 6px;
                            }
                        }
                    }
                }
                .table-item {
                    display: flex;
                    padding: 10px;
                    align-items: center;
                    justify-content: center;
                    border-right: 1px solid #eee;
                    border-bottom: 1px solid #eee;
                    &.buyer-info {
                        width: 25%;
                        padding: 10px;
                        border-right: 1px solid #eee;
                        display: flex;
                        flex-direction: column;
                        align-items: center;
                        justify-content: center;
                        p {
                            margin: 6px;
                        }
                    }
                    .cell-btn-con {
                        position: relative;
                        background: rgb(45, 140, 240);
                        line-height: 24px;
                        border-radius: 4px;
                        cursor: pointer;
                        padding: 0 6px;
                        color: #fff;
                    }
                    .icon-wuliu {
                        width: 24px;
                        height: 24px;
                        text-align: center;
                        line-height: 24px;
                        color: #fff;
                        border-radius: 4px;
                        cursor: pointer;
                        margin-right: 5px;
                    }
                }
            }
        }
    }
    .el-dialog {
        padding: 15px;
        .el-dialog__header {
            padding: 10px 15px;
            background: #f5f5f5;
            border: 1px solid #ddd;
            border-top-left-radius: 4px;
            border-top-right-radius: 4px;
            .el-dialog__headerbtn {
                top: 28px;
                right: 30px;
            }
            .el-dialog__title {
                color: #333;
                font-size: 12px;
                line-height: 12px;
            }
        }
        .el-dialog__body {
            padding: 0;
            .body-panel {
                padding: 10px 15px 30px;
                border: 1px solid #ddd;
                border-top: none;
                border-bottom-left-radius: 4px;
                border-bottom-left-radius: 4px;
                .post-type-container {
                    display: flex;
                    margin-top: 20px;
                    cursor: pointer;
                    .post-type-item {
                        padding: 10px 15px;
                    }
                    .active {
                        color: #2d8cf0;
                        border: 1px solid #ddd;
                        border-bottom-color: #fff;
                        border-radius: 4px 4px 0 0;
                        margin-bottom: -1px;
                    }
                }
                .post-container {
                    // padding: 10px 0;
                    border: 1px solid #ddd;
                    .ziti .el-input__inner {
                        border-radius: 0;
                        border: none;
                        // font-size: 54px;
                        // height: 100px;
                    }
                    .kuaidi {
                        display: flex;
                    }
                    .post-item {
                        display: flex;
                        align-items: center;
                        padding: 15px;
                        width: calc((100% - 1px) / 2);
                        .iconfont {
                            font-size: 30px;
                            color: rgb(45, 140, 240);
                            margin-right: 40px;
                        }
                        > div {
                            font-size: 12px;
                        }
                        &.addr-info {
                            border-right: 1px solid #ddd;
                        }
                        &.post-info {
                            .p-i {
                                display: flex;
                                align-items: center;
                                margin: 10px 0;
                                p {
                                    width: 60px;
                                }
                                .ele-container {
                                    flex: 1;
                                }
                            }
                        }
                    }
                }
            }
            .btn-contaienr {
                padding: 20px;
                display: flex;
                &.mini {
                    padding-bottom: 10px;
                }
                .send {
                    background: rgb(45, 140, 240);
                    margin: auto;
                    // padding: 10px 20px;
                    color: #fff;
                    font-size: 16px;
                    border-radius: 4px;
                    width: 160px;
                    text-align: center;
                    cursor: pointer;
                }
            }
        }
    }
}
.cell-btn-con:hover > .post-info-panel {
    display: block;
}
.post-info-panel {
    display: none;
    position: absolute;
    top: 0;
    right: calc(100% + 10px);
    transform: translateY(calc(-50% + 10px));
    min-width: 220px;
    background: #fff;
    color: #000;
    border: 1px solid #dcdcdc;
    border-radius: 10px;
    padding: 10px;
    .tab-card-panel {
        display: flex;
        .tab-card {
            padding: 0 10px;
            background-color: rgb(238, 238, 238);
            border: 1px solid #dcdfe6;
            &:first-child {
                border-top-left-radius: 6px;
            }
            &:last-child {
                border-top-right-radius: 6px;
            }
            &.active {
                background-color: #fff;
                margin-bottom: -2px;
                border-bottom-color: #fff;
            }
        }
    }
    .tab-content {
        padding: 10px;
        border: 1px solid #dcdfe6;
    }
    &::after {
        content: "";
        position: absolute;
        top: 50%;
        left: 100%;
        transform: translateY(-50%);
        border-left: 10px solid #dcdcdc;
        border-top: 10px solid transparent;
        border-bottom: 10px solid transparent;
    }
}
</style>
